<template>
    <div id="CommercialPolicy">
        <section id="commercial-policy-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">特定商取引法に基づく表記</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section
            id="commercial-policy-title"
            class="title-section-style hide-mb"
        >
            <h2 class="title f29">特定商取引法に基づく表記</h2>
        </section>
        <section
            id="commercial-policy-main"
            class="w80 w85-mb serif f14 f12-mb"
        >
            <div class="commercial-policy-list jump-list-style">
                <li @click="scrollToElement('#book-table')">
                    <i class="icon-page-link"> </i> 書籍その他物品
                </li>
            </div>
            <article id="book-table" class="commercial-policy-table-wrap">
                <h2 class="f18 f16-mb bold">書籍その他物品</h2>
                <ul class="commercial-policy-table">
                    <li
                        v-for="(item, index) in bookTable"
                        :key="'commercial-policy-item-' + index"
                    >
                        <span class="head">{{ item.head }}</span>
                        <span class="body" v-html="item.body"></span>
                    </li>
                </ul>
            </article>
        </section>
    </div>
</template>
<script>
export default {
    name: 'CommercialPolicy',
}
</script>
<script setup>
import { addBackgroundImage, scrollToElement } from '@/utils/common.js'
import getCommercialPolicyData from '@/data/getCommercialPolicyData.js'

const { bookTable } = getCommercialPolicyData()
</script>
<style lang="scss" scoped>
#commercial-policy-main {
    .commercial-policy-list {
        margin-top: -5em;
        margin-bottom: 3em;
    }
    ul.commercial-policy-table {
        li {
            margin-top: 1em;
            display: flex;
        }
        span {
            display: block;
        }
        .head {
            margin-right: 3em;
            flex: 0 0 8em;
        }
    }
    article {
        border-top: 1px solid $grayE;
    }
}
@media screen and (min-width: 768px) {
    ul.commercial-policy-table {
        margin-bottom: 50px;
    }
    article {
        padding: 60px 0;
    }
}
@media screen and(max-width:767px) {
    ul,
    article {
        padding: 30px 0;
    }
}
</style>
