export default function () {
    const bookTable = [
        { head: '販売業者', body: '大自然界' },
        { head: '運営責任者', body: '濱野祥子' },
        { head: '本部所在地', body: '〒299-5202 千葉県勝浦市市野川1373番地' },
        { head: '電話番号', body: '0470-77-1773' },
        { head: '電話受付時間', body: '平日 10:00～17:00' },
        { head: 'メールアドレス', body: 'inquiry@daishizenkai.or.jp' },
        { head: 'ホームページURL', body: 'https://www.daishizenkai.or.jp' },
        {
            head: '販売価格',
            body: '価格の表記は、商品の本体価格と消費税を含めた総額表示としております。\
            <ul class="star-list-ul">\
                <li class="star-list-li">上記には、配送料・手数料は含まれません。</li> \
                <li class="star-list-li">消費税率の変更により、表示価格が変更される場合がございます。</li> \
                <li class="star-list-li">ご購入者様のご注文確定時の当サイト上の表示価格と、ご請求時のサイト上の表示価格が一致しない場合については、原則として、ご購入者様ご注文確定時の表示価格が商品価格となります。</li> \
            </ul>',
        },
        {
            head: '送料について',
            body: '商品やお届けの地域によって送料が異なります。後ほど送料、輸送手段などの確認メールをお送りします。',
        },
        {
            head: '商品代金以外の必要料金',
            body: '下記は全てお客様負担となります。\
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">クレジットカード決済：分割払いの金利</li>\
                    <li class="disc-list-li">銀行振込：銀行振込手数料</li>\
                    <li class="disc-list-li">郵便振替：振替手数料</li>\
                </ul> ',
        },
        {
            head: 'お支払い方法',
            body: '<ul class="disc-list-ul">\
                    <li class="disc-list-li">クレジットカード決済（Visa、Mastercard、American Express）</li>\
                    <li class="disc-list-li">銀行振込(先払い)</li>\
                    <li class="disc-list-li">郵便振替(先払い)</li>\
                </ul> ',
        },
        {
            head: '領収書の発行について',
            body: '領収書につきましては、以下に代えさせていただきます。\
                <ul class= "disc-list-ul" >\
                    <li class="disc-list-li">\
                        クレジットカード決済：</br>\
                        クレジットカード会社が発行する「ご利用明細書」\
                    </li >\
                    <li class="disc-list-li">\
                        銀行振込：</br>\
                        「振込金（兼手数料）受取書」または</br>\
                        各金融機関から発行される振込証明\
                    </li>\
                    <li class="disc-list-li">\
                        郵便振替：</br>\
                        「振替払込請求書兼受領書」\
                    </li>\
                </ul>\
                <ul class="star-list-ul">\
                    <li class="star-list-li">個別の形式での領収書発行には対応しておりませんので、その旨ご了承下さい。</li>\
                    <li class="star-list-li">なお、個別の形式での領収書を発行できないという理由により返品をされる場合には、返送料はご購入者様の負担となります。あらかじめご了承ください。</li>\
                </ul>',
        },
        {
            head: '納期・お届けの目安について',
            body: '在庫ありの場合は、2～5営業日でお届けとなります。</br>\
                なお表示にかかわらず、以下の理由によりご注文をお断りさせていただく場合がございます。\
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">確保していた在庫商品に汚損・破損があった場合</li>\
                    <li class="disc-list-li">確保分の在庫を上回るご注文が集中した場合</li>\
                    <li class="disc-list-li">取り寄せができなかった場合</li>\
                </ul> ',
        },
        {
            head: '欠損・不具合等の取り扱い',
            body: '商品に初期不良や不具合がある場合、商品到着後30日以内に当社所定の方法により申請いただければ、商品の返品及び返金に対応いたします。</br>\
                商品到着後31日以上経過した場合には、ご対応できません。',
        },
        {
            head: '返品について',
            body: 'お客様の都合により商品の返品・交換を希望される場合、以下の場合にはお受けできません。\
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">商品をご使用・ご開封された場合</li>\
                    <li class="disc-list-li">商品到着後31日以上経過した場合</li>\
                    <li class="disc-list-li">お客様の過失により商品に汚れ、破損、故障などが生じた場合</li>\
                    <li class="disc-list-li">同一、または同等の商品を大量に購入された場合</li>\
                </ul> ',
        },
    ]

    return { bookTable }
}
